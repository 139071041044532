export const publications = [
  {
    type: 'paper',
    value: 'spacialAwareness',
    date: { day: 0, month: 10, year: 2021 },
    dateDescription: 'October 2022',
    title:
      'Uncovering Visually Impaired Gamers’ Preferences for Spatial Awareness Tools Within Video Games',
    abstract:
      'Sighted players gain spatial awareness within video games through sight and spatial awareness tools (SATs) such as minimaps. Visually impaired players (VIPs), however, must often rely heavily on SATs to gain spatial awareness, especially in complex environments where using rich ambient sound design alone may be insufficient. Researchers have developed many SATs for facilitating spatial awareness within VIPs. Yet this abundance disguises a gap in our understanding about how exactly these approaches assist VIPs in gaining spatial awareness and what their relative merits and limitations are. To address this, we investigate four leading approaches to facilitating spatial awareness for VIPs within a 3D video game context. Our findings uncover new insights into SATs for VIPs within video games, including that VIPs value position and orientation information the most from an SAT; that none of the approaches we investigated convey position and orientation effectively; and that VIPs highly value the ability to customize SATs.',
    authors: ['Vishnu Nair', 'Shao-en Ma', 'Ricardo E. Gonzalez Penuela', 'Yicheng He', 'Karen Lin', 'Mason Hayes', 'Hannah Huddleston', 'Matthew Donnelly', 'Brian A. Smith'],
    book:
      "ASSETS '22: Proceedings of the 24th International ACM SIGACCESS Conference on Computers and Accessibility",
    location: 'Athens, Greece',
    link: 'https://dl.acm.org/doi/10.1145/3517428.3544802',
    download: '/files/Uncovering_Visually_Impaired_Gamers_Preferences_for_Spatial.pdf',
    relavance: [],
    tags: ['cs', 'hci', 'coding'],
  },
  {
    type: 'demo',
    value: 'acousticMinimap',
    date: { day: 0, month: 10, year: 2021 },
    dateDescription: 'October 2021',
    title:
      'Towards a Generalized Acoustic Minimap for Visually Impaired Gamers',
    abstract:
      'Video games created for visually impaired players (VIPs) remain inequivalent to those created for sighted players. Sighted players use minimaps within games to learn how their surrounding environment is laid out, but there is no effective analogue to the minimap for visually impaired players. A major accessibility challenge is to create a generalized, acoustic (non-visual) version of the minimap for VIPs. To address this challenge, we develop and investigate four acoustic minimap techniques which represent a breadth of ideas for how an acoustic minimap might work: a companion smartphone app, echolocation, a directional scanner, and a simple menu. Each technique is designed to communicate information about the area around the player within a game world, providing functionality analogous to a visual minimap but in acoustic form. We close by describing a user study that we are performing with these techniques to investigate the factors that are important in the design of acoustic minimap tools.',
    authors: ['Vishnu Nair', 'Shao-en Ma', 'Hannah Huddleston', 'Karen Lin', 'Mason Hayes', 'Matthew Donnelly', 'Ricardo Gonzalez', 'Yicheng He', 'Brian A. Smith'],
    book:
      "UIST '21: The 34th Annual ACM Symposium on User Interface Software and Technology",
    location: 'Virtual',
    link: 'https://dl.acm.org/doi/10.1145/3474349.3480177',
    download: '/files/Towards_a_Generalized_Acoustic_Minimap_for_Visually_Impaired_Gamers.pdf',
    relavance: [],
    tags: ['cs', 'hci', 'coding'],
  },
  {
    type: 'paper',
    value: 'irelics',
    date: { day: 17, month: 3, year: 2019 },
    dateDescription: 'March 2019',
    title:
      'IRelics: Designing a Tangible Interaction Platform for the Popularization of Field Archaeology',
    abstract:
      'We present IRelics, a tangible interaction platform for the popularization of field archeology. IRelics allows users to experience archaeological field work activities as a serious game by using a set of tangible tools. We developed an innovative LWIR (Long Wavelength Infrared Rays) sensing system, which implements the design of tangible tools that provide real manipulation experiences. By interacting with IRelics, a player may experience different archaeological activities such as excavation and cleaning. We conducted two observations to evaluate the usability and effectiveness at archeology popularizing. Findings suggest that the IRelics platform can enhance the engagement of the participants by providing a positive and interactive environment while teaching them unfamiliar knowledge.',
    authors: ['Qi Lu', 'Shao-en Ma', 'Jiayin Li', 'Haipeng Mi', 'Yingqing Xu'],
    book:
      "TEI '19: Proceedings of the Thirteenth International Conference on Tangible, Embedded, and Embodied Interaction",
    location: 'Tempe, Arizona, USA',
    link: 'https://dl.acm.org/doi/10.1145/3294109.3295647',
    download:
      '/files/IRELICS_Designing_a_Tangible_Interaction_Platform_for_the_Popularization_of_Field_Archaeology.pdf',
    relavance: [],
    tags: ['cs', 'hci', 'ee', 'coding'],
  },
];
