export const education = [
  {
    value: 'columbiaUG',
    startDate: { day: 0, month: 3, year: 2019 },
    endDate: { day: 0, month: 3, year: 2019 },
    dateDescription: 'Fall 2020 – Present',
    institution: 'Columbia University, The Fu Foundation School of Engineering and Applied Science',
    location: 'New York, NY, USA',
    degree: 'B.S.',
    gpa: 'N/A',
    info: [
      'Major: Computer Science',
      'Attending for 2 years as part of the Columbia 3-2 Combined Plan Program',
    ],
  },
  {
    value: 'colgateUG',
    startDate: { day: 0, month: 3, year: 2019 },
    endDate: { day: 0, month: 3, year: 2019 },
    dateDescription: 'Fall 2017 – Spring 2020',
    institution: 'Colgate University',
    location: 'Hamilton, NY, USA',
    degree: 'B.A.',
    gpa: '3.51',
    info: [
      'Major: Physics',
      'Minors: Mathematics, Jewish Studies',
      'Attended for 3 years as part of the Columbia 3-2 Combined Plan Program',
      'Extended Studies to Uzbekistan and Azerbaijan (SOSC 210: Oil and Water), and Lithuania (POSC/JWST 329: Politics of Nationalism and Memory)',
    ],
  },
  /*{
    value: 'dcb',
    startDate: { day: 0, month: 3, year: 2019 },
    endDate: { day: 0, month: 3, year: 2019 },
    dateDescription: 'Fall 2014 – Spring 2016',
    institution: 'Dulwich College Beijing',
    location: 'Beijing, China',
    degree: 'International Baccalaureate',
    gpa: 'N/A',
    info: [
      'HL courses: Physics, Computer Science, Mathematics',
      'SL courses: English Language & Literature, Economics, Chinese',
    ],
  },*/
];
