export const certifications = [
  {
    type: '',
    month: 'November',
    year: '2016',
    title: 'Reasoning, Data Analysis, and Writing Specialization',
    note: null,
    description:
      'A collection of three courses dedicated to how to reason, analyze, and write critically followed by an 8-week final project. The Reasoning, Data Analysis, and Writing Specialization teaches skills needed to build a well-reasoned, critical argument in writing. Successful participants will be able to analyze data, evaluate arguments, and write persuasively.',
    institution: 'Duke University, Coursera',
    tags: ['math', 'apmath'],
    relevance: ['software', 'maths', 'data', 'communication', 'research', 'design'],
  },
  {
    type: '',
    month: 'October',
    year: '2014',
    title: 'The Data Scientist’s Toolbox',
    note: 'with Distinction',
    description:
      'The course gives an overview of the data, questions, and tools that data analysts and data scientists work with. There are two components to this course. The first is a conceptual introduction to the ideas behind turning data into actionable knowledge. The second is a practical introduction to the tools that will be used in the program like version control, markdown, git, GitHub, R, and RStudio.',
    institution: 'Johns Hopkins University, Coursera',
    tags: ['math', 'apmath'],
    relevance: ['software', 'maths', 'data', 'research', 'design'],
  },
  {
    type: '',
    month: 'April',
    year: '2014',
    title: 'Marine Megafauna: An Introduction to Marine Science and Conservation',
    note: 'with Distinction',
    description:
      'This course is about sea turtles, whales, dolphins, seals, penguins, sharks, giant squid and other large ocean creatures - collectively known as marine megafauna - and what they can tell us about how the ocean works and why it is so important for all life on earth.',
    institution: 'Duke University, Coursera',
    tags: [],
    relevance: ['communication', 'research'],
  },
  {
    type: '',
    month: 'November',
    year: '2013',
    title: 'Introduction to Mathematical Thinking',
    note: null,
    description:
      'The ten week course focused on the development of mathematical thinking skills, and comprised lectures, quizzes, ungraded assignments, graded Problem Sets, and a Final Exam. The topics were logical combinators; implication; quantifiers; proofs; number theory; real analysis.',
    institution: 'Stanford University, Coursera',
    tags: [],
    relevance: ['maths'],
  },
];
