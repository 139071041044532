export const experiences = [
  {
    value: 'uidesign',
    startDate: { day: 1, month: 9, year: 2021 },
    endDate: { day: 1, month: 12, year: 2021 },
    dateDescription: 'Fall 2021',
    label: 'Columbia University, COMS W4170 User Interface Design',
    jobTitle: 'Teaching Assistant',
    location: 'New York, NY, USA',
    description: [
      'Served as mentor to 16 students, providing guidance and feedback as they worked on an individual design project using the User Centered Design process.',
      'Guided students as they performed Contextual Inquiries, Storyboarding, Rapid Prototyping, and Study Design.',
      'Assisted students in learning design software such as Basalmiq and Figma, as well as web technologies such as HTML/CSS/Javascript.',
      'Answered student questions and provided advice at office hours and online and graded bi-weekly homework assignments.'
    ],
    relevance: ['research', 'software', 'design', 'data', 'leadership'],
    tags: [],
  },
  {
    value: 'ceal',
    startDate: { day: 1, month: 6, year: 2019 },
    endDate: { day: 1, month: 9, year: 2019 },
    dateDescription: 'Fall 2020 - Summer 2021',
    label: 'Columbia University, (HCI) Computer-Enabled Abilities Laboratory',
    jobTitle: 'Research Assistant',
    location: 'New York, NY, USA',
    description: [
      'Conducted research with Prof. Brian Smith on techniques to enable blind accessibility in video games.',
      'Helped implement a new blind-enabled interaction method in Unity based on joystick-controlled ray casting.',
      'Designed and performed numerous user studies with visually impaired gamers. Performed analysis and participated in written presentation of results.',
      'Second author on two resulting publications, one presented at UIST and the other currently under review at CHI.'
    ],
    relevance: ['research', 'software', 'design', 'data', 'maths'],
    tags: [],
  },
  {
    value: 'colgatePhysics',
    startDate: { day: 1, month: 6, year: 2019 },
    endDate: { day: 1, month: 9, year: 2019 },
    dateDescription: 'Summer 2020',
    label: 'Colgate University, Department of Physics',
    jobTitle: 'Research Assistant',
    location: 'Hamilton, NY, USA',
    description: [
      'Conducted research with Prof. Ken Segall on simulations of neurons using Superconducting Josephson Junctions. Purpose was to use neural dynamics to create logic components that could operate 100 times faster and be 300 times more efficient than traditional transistor based logic circuits.',
      'Successfully created first working Josephson-Junction-neuron based half-adder, results allowed a clearer path to creating other logical components based on JJ-neurons.',
      'Used Xic and WRspice to design and simulate circuits. Wrote scripts to automate testing of circuits and used MATLAB to analyze data.',
    ],
    relevance: ['research', 'hardware', 'data', 'software', 'maths'],
    tags: [],
  },
  {
    value: 'borderx',
    startDate: { day: 1, month: 6, year: 2019 },
    endDate: { day: 1, month: 9, year: 2019 },
    dateDescription: 'Summer 2019',
    label: 'BorderX Lab',
    jobTitle: 'Front-end Engineer Intern',
    location: 'Shanghai, China',
    description: [
      'Built internal software from scratch using React in collaboration with a backend programmer and a PM. System now used by entire sales team to easily send digital coupons to all customers via methods such as SMS, push notifications, email, etc., saving time and improving efficiency over previous methods of doing so.',
      'Added features and user interface improvements to other internal systems written in legacy AngularJS, Angular, or React. Improvements allowed significant time savings for sales team.',
      'Gained experience in interfacing with back-end systems, using continuous deployment, and writing pipeline scripts.',
    ],
    relevance: ['software', 'communication', 'design'],
    tags: [],
  },
  {
    value: 'hulu',
    startDate: { day: 1, month: 6, year: 2019 },
    endDate: { day: 1, month: 9, year: 2019 },
    dateDescription: 'Summer 2018',
    label: 'Hulu',
    jobTitle: 'Intern',
    location: 'Beijing, China',
    description: [
      'Participated in an agile team and supported efforts to upgrade and maintain the front-end systems of Hulu.com.',
      'Gained better understanding of industry concepts such as MVC, server architecture, VOD, REST API, CDN, load distribution, web routing, cookies etc., as well as corporate processes such as Product Owners, QC, code review, etc., and aspects of Agile development such as Scrum and Jira.',
      'Migrated around a thousand test cases to a different testing framework and introduced snapshot testing to certain tests. Also wrote original test cases for a microservice written in Go and increased code coverage to 100% for multiple files.',
      'Fixed bug in eslint-import-resolver-babel-module, an open source NPM package with 143,000+ weekly downloads.',
      'Participated in Hulu Beijing Office Hackathon in a team of three and won “Coolest Hackathon Project” with RMB 2,000 prize out of 22 competing teams. Project was completed in 2 days and consisted of a “Katamari” ball able to pick up elements of any website and graphically display them as if picked up by a 3-dimensional ball. Wrote particle system and other physics effects based on knowledge from Physics courses.',
    ],
    relevance: ['software'],
    tags: [],
  },
  {
    value: 'colgateHCI',
    startDate: { day: 1, month: 6, year: 2019 },
    endDate: { day: 1, month: 9, year: 2019 },
    dateDescription: 'Spring 2018',
    label: 'Colgate University, Department of Computer Science, HCI Lab',
    jobTitle: 'Faculty Research Assistant',
    location: 'Hamilton, NY, USA',
    description: [
      'Participated in the development of a videoconferencing web service under the direction of Prof. Madeline Smith. Project allows for synced video-watching using HTML, CSS, JavaScript, WebRTC, Socket.io, and Apache web server.',
      'Provided technical assistance to other members of the team. ',
    ],
    relevance: ['software', 'research'],
    tags: [],
  },
  {
    value: 'colgateAnthropology',
    startDate: { day: 1, month: 6, year: 2019 },
    endDate: { day: 1, month: 9, year: 2019 },
    dateDescription: 'Fall 2017',
    label: 'Colgate University, Department of Anthropology',
    jobTitle: 'Assistant Archaeological Illustrator',
    location: 'Hamilton, NY, USA',
    description: [
      'Used Adobe Illustrator to convert field drawings of archaeological dig sites in Mexico into detailed digital illustrations suitable for publication.',
    ],
    relevance: ['design', 'communication'],
    tags: [],
  },
  {
    value: 'xStudio',
    startDate: { day: 1, month: 6, year: 2019 },
    endDate: { day: 1, month: 9, year: 2019 },
    dateDescription: 'Fall 2016 – Summer 2017, Winter 2017',
    label: 'Tsinghua University, Department of Information Art and Design',
    jobTitle: 'Research Intern',
    location: 'Beijing, China',
    description: [
      'Completed, debugged, and finished major component of an interactive storybook written in Unity that connects to a custom capacitive device able to provide haptic/textural feedback within a 3 day deadline. Learnt to use Microsoft Foundation Classes, Unity inter-process communication, and C++ inter-process communication. Successful completion within deadline and demonstration in front of exhibition visitors.',
      'Collaborated with graduate students to build interactive device that tracks input on a surface using temperature changes and a game based on the device. Implemented particle system, parts of input detection and game logic, and Arduino code for haptic tools. Used C++ OpenFrameworks library, XBee wireless communication, and various sensors for the haptic tools.',
      'Participated in numerous other projects. Edited 10+ papers in support of publication efforts and participated in design of user studies.',
    ],
    relevance: ['software', 'hardware', 'research', 'design', 'maths', 'data', 'communication'],
    tags: [],
  },
  {
    value: 'cy',
    startDate: { day: 1, month: 6, year: 2019 },
    endDate: { day: 1, month: 9, year: 2019 },
    dateDescription: 'Summer 2017',
    label: 'CYDesign Ltd',
    jobTitle: 'Programmer',
    location: 'Beijing, China',
    description: [
      'Brought in by recommendation to debug and implement Kinect gesture control on a motion controlled robotic arm controlled via the internet. Used C# with Unity3D and Microsoft Kinect SDK.',
      'Exhibited result to the public at “Global Artificial Intelligence Summit Forum & Launching Ceremony of China (Hangzhou) Artificial Intelligence Town” as an official business exhibitor.',
    ],
    relevance: ['software', 'hardware'],
    tags: [],
  },
];

export const otherExperiences = [
  {
    value: 'photoclub',
    startDate: { day: 1, month: 6, year: 2019 },
    endDate: { day: 1, month: 9, year: 2019 },
    dateDescription: 'Fall 2018 – Spring 2020',
    label: 'Colgate University Photography Club',
    jobTitle: 'President (Spring 2020), Treasurer (Fall 2018 – Fall 2019)',
    location: 'Hamilton, NY, USA',
    description: [
      'Managed finances and collaborated with team members to successfully receive funding of around 19,000 USD to procure photography equipment.',
      'Assisted in organizing system for managing equipment lending, including the drafting of liability clauses and system for inventorying.',
      'Ran photography workshops on topics such as composition and photography software.',
      'Helped recruit new members, review leadership team applications, and organize projects such as Humans of Colgate and photography exhibitions.',
    ],
    relevance: ['leadership', 'communication', 'design'],
    tags: [],
  },
  {
    value: 'marketingclub',
    startDate: { day: 1, month: 6, year: 2019 },
    endDate: { day: 1, month: 9, year: 2019 },
    dateDescription: 'Fall 2017',
    label: 'Colgate University Marketing Club',
    jobTitle: 'Director of Graphic Design',
    location: 'Hamilton, NY, USA',
    description: [
      'Designed marketing material for other clubs for purposes of recruitment.',
      'Planned workshops on Graphic Design and Adobe Illustrator.',
    ],
    relevance: ['leadership', 'communication', 'design'],
    tags: [],
  },
  {
    value: 'livestream',
    startDate: { day: 1, month: 6, year: 2019 },
    endDate: { day: 1, month: 9, year: 2019 },
    dateDescription: 'Fall 2014 – Spring 2016',
    label: 'High School Event Livestream Team',
    jobTitle: 'Team Director',
    location: 'Beijing, China',
    description: [
      'Directed and coordinated teams of 2-3 cameramen to film school events from multiple angles.',
      'Edited audio and video in real time using NewTek Tricaster.',
      'Streamed events to Livestream.com for an audience of 80+',
    ],
    relevance: ['leadership', 'communication',],
    tags: [],
  },
  {
    value: 'raspberrypi',
    startDate: { day: 1, month: 6, year: 2019 },
    endDate: { day: 1, month: 9, year: 2019 },
    dateDescription: 'Fall 2013 – Spring 2014',
    label: 'High School Raspberry Pi Club',
    jobTitle: 'Co-President',
    location: 'Beijing, China',
    description: [
      'Founded club with a group of peers and organised lessons using Raspberry Pi computers to teach Python programming, circuits, and electronics.',
    ],
    relevance: ['leadership', 'software', 'hardware'],
    tags: [],
  },
  {
    value: 'stuco',
    startDate: { day: 1, month: 6, year: 2019 },
    endDate: { day: 1, month: 9, year: 2019 },
    dateDescription: 'Fall 2013 – Spring 2014',
    label: 'High School Student Council',
    jobTitle: 'Director of Marketing Department and Lead Illustrator',
    location: 'Beijing, China',
    description: [
      'Led marketing department in which students made marketing material for student council sponsored events.',
      'Designed numerous posters for events and clubs using Adobe Photoshop and Illustrator.',
    ],
    relevance: ['leadership', 'communication', 'design'],
    tags: [],
  },
  
];

export const skills = {
  progLangs: {
    proficient: 'C++, C#, C, Java, JavaScript, MATLAB, Python',
    familiar: 'R, Processing, Go, Mathematica, Assembly',
  },
  technologies: {
    proficient:
      'Unity, HTML/CSS, React, Angular, AngularJS, Teensy, Arduino, OpenFrameworks, Git, Xic, WRspice',
    familiar:
      'Heroku, Kinect SDK, Hololens SDK, Optris Pi SDK, Unreal Engine, Zigbee, MFC, 3D Printing, Next.js, Mocha, Jest, LaTeX, Google Analytics',
  },
  arts: {
    proficient: 'Figma, Basalmiq, Adobe XD, Illustrator, Photoshop, Premiere Pro',
    familiar: 'Adobe After Effects',
  },
};
